
.input-wrapper {
    position: relative;
}
.input {
    background: #E6E4F9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.1rem;
    padding:10px 10px;
    border-radius: 10px;
    position: relative;
}
.input input {
    border: none;
    outline: none;
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    padding: 8px 8px;
    font-size: 1rem;
    background: #E6E4F9;
    width: 100%;
}
.input input::placeholder {
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}
@media (min-width: 450px) {
    .input {
       width: 320px;
    }
}
@media (min-width: 500px) {
    .input {
       width: 380px;
    }
}
@media (min-width: 700px) {
    .input {
        padding: 8px 15px;
    }
}
@media(min-width:1100px) {
    .input {
        width: 350px;
        padding:8px 14px;
        border-radius: 10px;
        margin-top: 1.5rem;
    }
    .input input {
        padding: 6px 8px;
        font-size: .9rem;
    }
    .input input::placeholder {
        font-size: .9rem;
    }
}
@media(min-width:1650px) {
    .input {
        width: 470px;
        padding:13px 14px;
        border-radius: 10px;
        margin-top: 1.8rem;
    }
    .input input {    
        font-size: 1.2rem;
    }
    .input input::placeholder {
        font-size: 1.2rem;
    }
}


.icon_ani {
    animation: shake 0.75s; 
    animation-iteration-count: 1;
}

@keyframes shake {
    0%, 20%, 40%, 60%, 80% {
      transform: translateX(4px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-4px);
    }
  }
  
  /* @keyframes glow-red {
    50% {
      border-color: indianred;
    }
  } */