@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  overflow: hidden;
}
#sign-up {
    background-color: #E6E4F9;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}



@media (min-width: 1100px) and (max-height: 590px) {
  #sign-up {
    height: 45rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
@media (min-width: 1650px) and (max-height: 830px) {
  #sign-up {
    height: 60rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}

.ilus {
    position: absolute;
    bottom: -4.8rem;
    left: -4rem;
    display: none;
}
@media (min-width: 1100px) {
   .ilus {
    bottom: -3.7rem;
    left: -3.4rem;
    width: 30rem;
    display: block;
    }
}
@media (min-width: 1650px) {
   .ilus {
    bottom: -5.3rem;
    left: -4.5rem;
    width: 43rem;
   }
}
/* Logo Style */

.logo {
  display: flex;
  align-items: center;
}

.logo .logo-text {
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: #5D54C2;
}

#top-bar-main .logo {
  padding: 2rem !important;
}

#top-bar-main .logo {
  flex: 1 1;
}

#top-bar-main .logo-text {
  letter-spacing: 0.8px;
} 

#top-bar .logo-text {
  font-size: 18px !important;
  margin-left: 10px;
} 
#top-bar .logo-img {
 width: 37px !important;
} 
#top-bar .logo {
 padding: 0rem !important;
}


@media (min-width: 1150px) {
  .logo {
    padding: 2rem;
  }
  .logo .logo-text {
    font-size: 1.8rem;
    margin-left: 1rem;
  } 
  .logo-img {
    width: 3.5rem;
  }
  #top-bar .logo-text {
    font-size: 1.3rem !important;
    margin-left: 1.3rem;
  } 
 #top-bar .logo-img {
   width: 2.8rem !important;
 } 
 #top-bar .logo {
   padding: 2rem 0rem !important;
 } 
}
@media (min-width: 1650px) {
  .logo {
    padding: 3rem;
  }
  .logo .logo-text {
    font-size: 2.4rem;
    margin-left: 1.5rem;
  } 
  .logo-img {
    width: 4.7rem;
  }
  #top-bar .logo-text {
    font-size: 1.9rem !important;
  } 
 #top-bar .logo-img {
   width: 4rem !important;
 } 
 #top-bar .logo {
   padding: 2rem 0rem !important;
 } 
}



#rights {
    position: absolute;
    bottom: 2rem;
    right: 3rem;
    font-family: 'Poppins', sans-serif;
    display: none;
}
@media(min-width: 1100px) {
    #rights {
        display: block;
    }
} 
.wave {
    position: absolute;
    top:0;
    right: 0;
    height: 100vh;
    display: none;
}

@media (min-width: 1100px){
  .wave {
      display: block;
  }
}
#form {
    position: absolute;
    top:0%;
    left:0%;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    height: 100vh;
    width: 100%;
}

#form .form-wrapper{
    display: inline-block;
    border-radius: 0;
    z-index: 999;
    position: relative;
    width: 100%;
    background-color: #625AC0;
    height: 100%;
}
#form .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.form-rights {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size:0.75rem;
    font-weight: 300;
    font-family: 'Karla', sans-serif;
    color: white;
}
.inputs {
    display: flex;
    flex-direction: column;
    padding: .8rem 1.6rem 1rem 1.6rem;
    width: 100%;
}
.flex-inputs {
    display: flex;
}
.flex-inputs .input:nth-child(2){
    margin-left: 14px;
}
#form .MuiSvgIcon-root {
    fill: #625AC0 !important;
    font-size: 1.5rem !important;
}
#form  .visibility-password {
    font-size: 1rem !important;
    cursor: pointer;
}
@media (min-width: 450px) {
    .flex-inputs .input{
       width: calc(320px/2 - 7px);
    } 
    .inputs {
        width: auto;
    }
}
@media (min-width: 500px) {
    .flex-inputs .input{
       width: calc(380px/2 - 7px);
    } 
}
@media(min-width:1100px) {
    .form-rights {
       display: none;
    }
    #form {
        position: absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: auto;
        width: auto;
    }
    #form .form-wrapper{
        padding:3rem 0 1.5rem 0;
        width: 100%;
        border-radius: 2rem;
    }
    .inputs {
        padding: .8rem 2.1rem 1rem 2.1rem;
    }
    .flex-inputs .input{
        width: calc(350px/2 - 12px);
     }
     .flex-inputs .input:nth-child(2) {
        margin-left: 24px;
    }
    .MuiSvgIcon-root {
        font-size: 1.5rem !important;
    }
    .visibility-password {
        font-size: 1rem !important;
    }
}
@media(min-width:1650px) {
    #form .form-wrapper{
        padding:4rem 0 1.9rem 0;
    }
    .inputs {
        padding: .8rem 2.6rem 1rem 2.6rem;
    }
    .flex-inputs .input{
        width: calc(470px/2 - 12px);
     }
     .flex-inputs .input:nth-child(2) {
        margin-left: 24px;
    }
    #form  .MuiSvgIcon-root {
        font-size: 2rem !important;
    }
    #form  .visibility-password {
        font-size: 1.2rem !important;
        cursor: pointer;
    }
}

@media(max-width: 380px) and (max-height: 480px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 45rem !important;
    }
}
@media(max-width: 380px) and (max-height: 560px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 42rem !important;
    }
}
@media(min-width: 381px) and (max-height: 484px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
}
@media(min-width: 381px) and (max-height: 560px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 42rem !important;
    }
}
@media(min-width: 420px) and (max-height: 420px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 37rem !important;
    }
}
@media(min-width: 500px) and (max-height: 600px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 40rem !important;
    }
}
@media(min-width: 700px) and (max-height: 650px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 42rem !important;
    }
}
@media(min-width: 1100px) {
    #form {
        height: auto !important;
    }
}

.input-wrapper {
    position: relative;
}
.input {
    background: #E6E4F9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.1rem;
    padding:10px 10px;
    border-radius: 10px;
    position: relative;
}
.input input {
    border: none;
    outline: none;
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    padding: 8px 8px;
    font-size: 1rem;
    background: #E6E4F9;
    width: 100%;
}
.input input::-webkit-input-placeholder {
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}
.input input:-ms-input-placeholder {
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}
.input input::placeholder {
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}
@media (min-width: 450px) {
    .input {
       width: 320px;
    }
}
@media (min-width: 500px) {
    .input {
       width: 380px;
    }
}
@media (min-width: 700px) {
    .input {
        padding: 8px 15px;
    }
}
@media(min-width:1100px) {
    .input {
        width: 350px;
        padding:8px 14px;
        border-radius: 10px;
        margin-top: 1.5rem;
    }
    .input input {
        padding: 6px 8px;
        font-size: .9rem;
    }
    .input input::-webkit-input-placeholder {
        font-size: .9rem;
    }
    .input input:-ms-input-placeholder {
        font-size: .9rem;
    }
    .input input::placeholder {
        font-size: .9rem;
    }
}
@media(min-width:1650px) {
    .input {
        width: 470px;
        padding:13px 14px;
        border-radius: 10px;
        margin-top: 1.8rem;
    }
    .input input {    
        font-size: 1.2rem;
    }
    .input input::-webkit-input-placeholder {
        font-size: 1.2rem;
    }
    .input input:-ms-input-placeholder {
        font-size: 1.2rem;
    }
    .input input::placeholder {
        font-size: 1.2rem;
    }
}


.icon_ani {
    -webkit-animation: shake 0.75s;
            animation: shake 0.75s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1;
}

@-webkit-keyframes shake {
    0%, 20%, 40%, 60%, 80% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }

@keyframes shake {
    0%, 20%, 40%, 60%, 80% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }
  
  /* @keyframes glow-red {
    50% {
      border-color: indianred;
    }
  } */
.form-flex-column-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.have-account {
    color: white;
    font-family: 'Karla', sans-serif;
    font-weight: 300;
    font-size: 0.75rem;
    margin-top: .7rem;
}
.have-account:nth-child(3) {
    margin-top: 0.3rem;
}
.have-account span a{
    text-decoration: underline;
    cursor: pointer;
    color: white;
}
.btn-form {
    color: #625AC0;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    background: #E6E4F9;
    border: none;
    outline: none;
    padding: 0.8rem 3rem;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
}
@media(min-width:1100px) {
    .have-account {
        margin-top: 1rem;
        font-size: 0.85rem;
    }
    .btn-form {
        padding: 0.75rem 3.7rem;
        border-radius: 12px;
        font-size: 1rem;
        margin-top: 0.7rem;
    }
}
@media(min-width:1650px) {
    .have-account {
        margin-top: 1rem;
        font-size: 0.95rem;
    }
    .btn-form {
        padding: 1.3rem 4.7rem;
        font-size: 1.4rem;
        margin-top: 1.1rem;
    }
}
.form-logo {
    width: 2.3rem;
    margin-bottom: 1rem;
}
#form .title {
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    color: white;
    text-align: center;
    width: 85%;
}
@media(min-width:1100px) {
    #form .title {
        font-size: 1.4rem;
    }  
    .form-logo {
        display: none;
    }
}
@media(min-width:1650px) {
    #form .title {
        font-size: 2rem;
    } 
}
#error-form {
    margin-top:1rem;
    font-family: "Karla",sans-serif;
    color: #DBD6FA;
    font-weight: bold;
    width: 65%;
    text-align: center;
    display: inline-block;
    font-size: 13px;
}
@media (min-width:1650px) {
    #error-form {
        font-size: 16px;
    }
}
#loading {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
#loading svg {
  -webkit-transform: scale(0.15);
          transform: scale(0.15);
}
.rect_1 {
  -webkit-animation: ani 1.8s ease;
          animation: ani 1.8s ease;
  opacity: 1;
}
.rect_2 {
  -webkit-animation: ani_2 1.8s ease ;
          animation: ani_2 1.8s ease ;
  opacity: 1;
}
.rect_3 {
  -webkit-animation: ani_3 1.8s ease;
          animation: ani_3 1.8s ease;
  opacity: 1;
}
.rect_4 {
  -webkit-animation: ani_4 1.8s ease;
          animation: ani_4 1.8s ease;
  opacity: 1;
}

/* @keyframes ani {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  25% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
}
@keyframes ani_2 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  35% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  70% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  85% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
}
@keyframes ani_3 {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  40% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  90% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
}
@keyframes ani_4 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  45% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  90% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  95% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
} */

@-webkit-keyframes ani {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  50% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}

@keyframes ani {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  50% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@-webkit-keyframes ani_2 {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  70% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_2 {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  70% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@-webkit-keyframes ani_3 {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  80% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_3 {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  80% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@-webkit-keyframes ani_4 {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  90% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_4 {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  90% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}

body {
  overflow: hidden;
}
#login {
    background-color: #E6E4F9;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}



@media (min-width: 1100px) and (max-height: 590px) {
  #login {
    height: 45rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
@media (min-width: 1650px) and (max-height: 830px) {
  #login {
    height: 60rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}


#forgot-password {
    background-color: #E6E4F9;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}



@media (min-width: 1100px) and (max-height: 590px) {
  #forgot-password{
    height: 65rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
@media (min-width: 1650px) and (max-height: 830px) {
  #forgot-password {
    height: 60rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
  
#dashboard-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dashboard-component-container {
    height: 87vh;
    overflow: hidden;
    background: #ECEAFC;
    position: relative;
}

.dashboard-component-wrapper {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin:0 auto;
    height: 100%; */
    height: 100%;
    width:100%;
    margin: 0 auto;
}


body {
    overflow: hidden;
}

@media(min-width: 1150px) {
 .dashboard-component-container {
     height: 87vh;
 }
 .dashboard-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin:0 auto;
    height: 100%; 
}

}
@media(min-width: 1650px) {
 .dashboard-component-container {
     height: 87vh;
 }
}
#left-bar-main {
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 95%;
    max-width: 550px;
    height: 95%;
    z-index: 10;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 14px;
    position: relative;
}
.top-left-search {
    margin: 0 auto;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choose-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.choose-left h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    color: #494949;
}
.choose-left .active{
    color: #625AC0;
}

.createGroupBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #625AC0;
    position: absolute;
    right: 7%;
    bottom: 4%;
    cursor: pointer;
}
.createGroupBtn::after {
    content: "";
    width: 45%;
    height: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.createGroupBtn::before {
    content: "";
    width: 3px;
    height: 45%;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.back-from-create {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #625AC0;
    cursor: pointer;
}
.back-from-create h4{
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
}
.back-from-create span{
    font-weight: 300;
}
.group-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
}
.group-name-wrapper input {
    background-color: #ECEAFC;
    outline: none;
    border: none;
    border-radius: 12px;
    padding: 12px 15px;
    width: 70%;
}
.group-name-wrapper button {
    width: 30%;
    margin-left: 8px;
    background-color: #625AC0;
    outline: none;
    border: none;
    border-radius: 12px;
    padding: 12px 0;
    cursor: pointer;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.3px;
}
.group-name-wrapper input::-webkit-input-placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.group-name-wrapper input:-ms-input-placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.group-name-wrapper input, .group-name-wrapper input::placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}


@media (min-width:1150px) {
    #left-bar-main {
        width: 27%;
        padding: 0;
        max-width: auto;
        top:0;
        left:0;
        -webkit-transform:translate(0);
                transform:translate(0);
        height: 90%;
        border-radius: 14px;
        position: relative;
        margin-right: 20px;
    }    
    .top-left-search {
        margin: 0 auto;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media(min-width: 1650px) {
    #left-bar-main {
        width: 27%;
        /* padding: 1.2rem 1.1rem 1.2rem 1.1rem; */
        height: 90%;
        border-radius: 14px;
        position: relative;
    }
    .top-left-search {
        margin: 0 auto;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.find-input {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    background-color: #ECEAFC;
    padding: 1.1rem 1.2rem;
    border-radius: 18px;
    width: 90%;
    margin-top: 1.5rem;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(226, 226, 226, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(226, 226, 226, 0.25));
}
.find-input .MuiSvgIcon-root {
    fill: #625AC0 !important;
    font-size: 1.5rem !important;
}
.find-input svg {
    margin-right: 7px;
}
.find-input .MuiSvgIcon-root {
    font-size: 2rem !important;
}
.find-input input {
    margin-right: 2px;
    background-color: transparent;
    outline: none;
    border: none;
}
.find-input input::-webkit-input-placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.find-input input:-ms-input-placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.find-input input, .find-input input::placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}

@media (min-width: 1100px) {
  
    .find-input svg {
        margin-right: 6px;
    }
    .find-input input::-webkit-input-placeholder {
        font-size: 14px;
    }
    .find-input input:-ms-input-placeholder {
        font-size: 14px;
    }
    .find-input input, .find-input input::placeholder {
        font-size: 14px;
    }
    .find-input .MuiSvgIcon-root {
        font-size: 1.7rem !important;
    }
}
@media (min-width: 1650px) {

    .find-input svg {
        margin-right: 7px;
    }
    .find-input input::-webkit-input-placeholder {
        font-size: 1.1rem;
    }
    .find-input input:-ms-input-placeholder {
        font-size: 1.1rem;
    }
    .find-input input, .find-input input::placeholder {
        font-size: 1.1rem;
    }
    .find-input .MuiSvgIcon-root {
        font-size: 2rem !important;
    }
}
.friends {
    height: calc(100% - 120px);
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
}
.no-friends {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14.8px;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 60%;
    text-align: center;
    margin: 2rem auto;
    color: #646464;
}
@media(min-width: 1150px) {
    .friends {
        height: calc(100% - 120px);
        margin-top: 10px;
    }
}
@media(min-width: 1650px) {
    .friends {
        height: calc(100% - 140px);
        margin-top: 0px;
    }
}
.friend {
    display: flex;
    padding:.9rem 1rem;
    margin-top: 10px;
    border-radius: 18px;
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(226, 226, 226, 0.25);
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 90%;
}
.friend h3 {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
    position: relative;
    color: #6B6B6B;
    top: -1px;
}
.active-friend {
   background-color: #F7F7FA;;
}
.image-section {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
}
.friend img {
    width: 47px;
    object-fit: fill;
    height: 47px;
}
.friend .name-message {
    margin-left: 10px;
}
.lastMessage {
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 13.5px;
    position: relative;
    top: -2px;
}
.bold-text {
    font-weight: 500;
    font-size: 13.5px;
    color: #616161;
}
/* @media (min-width:1100px) {
    .friend {
        padding:1rem 1rem;
        margin-top: 13px;
    } 
    .friend img {
        width: 50px;
    }
    .lastMessage {
        font-size: 12px;
    }
    .friend h3 {
        font-size: 18px;
    }
}
@media (min-width:1650px) {
    .friend {
        padding:1rem 1rem;
        margin-top: 15px;
    } 
    .friend img {
        width: 55px;
    }
    .lastMessage {
        font-size: 13px;
    }
    .friend h3 {
        font-size: 19px;
    }
} */
.online {
    background-color: #6cf85f;
    border:1px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom:0;
    right: 7px;
}
.friend .name-friend {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.friend .image-section {
    position: relative;
}
.friend .image-section img{
    border-radius: 50%;
}
.new-message {
    width: 8px;
    height: 8px;
    background: #625AC0;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}
.color-showness {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.plus-btn {
    width: 14px;
    height: 14px;
    background-color: #cacaca;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}
.plus::after {
    content: "";
    width: 2px;
    height: 50%;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.plus::before {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.min-btn {
    width: 14px;
    height: 14px;
    background-color: #625AC0;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}

.min::before {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 20px;
}

@media(min-width:1650px) {
    .friend {
        display: flex;
        padding:.9rem 1rem;
        margin-top: 18px;
        border-radius: 18px;
        box-shadow: 0px 4px 5px rgba(226, 226, 226, 0.25);
        align-items: center;
        cursor: pointer;
        position: relative;
        width: 90%;
    } 
}
/* Profile in TopBar */
.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.profile img {
    margin-left: 15px;
}

/* Open Profile in TopBar */
.click-profile {
    position: absolute;
    right: 20px;
    margin-top: .7rem;
    background-color: #fefefe;
    box-shadow: 0px 0px 10px rgba(224, 224, 224, 0.44);
    padding: 12px 10px;
    border-radius: 10px;
    -webkit-animation: scale .2s ease;
            animation: scale .2s ease;
    z-index: 1000;
}
@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(0.3);
                transform: scale(0.3);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale {
    0% {
        -webkit-transform: scale(0.3);
                transform: scale(0.3);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
.click-profile .my-profile,
.click-profile .my-settings,
.click-profile .my-logout  {
    display: flex;
}
.click-profile .my-profile {
    padding:12px 10px;
    border-bottom: .5px solid rgba(223, 223, 223, 0.8);
    align-items: center;
    margin-bottom: 1rem;
}
.click-profile .my-profile div{
    margin-right: 2rem;
}
#profile img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}
#profile .my-profile img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}
.click-profile .my-profile img{
   width: 70px !important;
   height: 70px !important;
   object-fit: cover;
}
.my-profile h3{
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #1F1F1F;
}
.my-profile p{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #202020;
}
.profile-options {
    padding: 1.2rem 1rem;
    border-radius: 15px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.profile-options:hover {
    background-color: #F4F4F4;
}
.profile-options .change-link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.profile-options div {
    display: flex;
    width: 100%;
}
.profile-options h4, .profile-options a{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #202020;
    margin-left: 8px;
    text-decoration: none;
}
.profile-options .MuiSvgIcon-root {
    color: #5f5f5f !important;
}
.profile-options .material-icons{
    color: #5f5f5f !important;
}
.profile-options:nth-child(3) {
    margin-top: .5rem;
}
.profile-reserved {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    color:#202020 ;
    padding:0 1rem;
    margin-top: 0.8rem;
}
.profile .MuiSvgIcon-root {
    fill: #494949 !important;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

@media(min-width: 1150px) {
    .click-profile {
        padding:0rem 1rem .8rem 1rem;
    }
    .click-profile .my-profile {
        padding:1rem 2.5rem .7rem 0.5rem;
    }
}
@media(min-width: 1650px) {
    #profile img {
        width: 50px;
        height: 50px;
    }
    .click-profile {
        position: absolute;
        right: 1.5rem;
        margin-top: .7rem;
        background-color: #f8f8f8;
        box-shadow: 0px 0px 10px rgba(202, 202, 202, 0.44);
        padding:0rem 1.3rem .8rem 1.3rem;
        border-radius: 10px;
        -webkit-animation: scale .2s ease;
                animation: scale .2s ease;
        z-index: 1000;
    }
    .click-profile .my-profile {
        padding:1.2rem 2.5rem .9rem 0.5rem;
        border-bottom: .5px solid rgba(223, 223, 223, 0.8);
        align-items: center;
        margin-bottom: 1rem;
    }
}
#top-bar {
    width: 100%;
    box-shadow: 0px 4px 6px rgba(235, 235, 235, 0.25);
    height: 13vh;
    z-index: 1000;
    background-color: white;
}

#top-bar .top-bar-container {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
}

@media(min-width: 1650px) {
 #top-bar {
     height: 13vh;
 }  
}
#center-bar {
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    margin: 0 auto;
    z-index: 10;
    display: none;
}

@media(min-width:1150px) {
    #center-bar {
        width: 73%;
        height: 90%;
        top:0;
        left:0;
        -webkit-transform: translate(0);
                transform: translate(0);
        position: relative;
        z-index: 10;
        display: block;
    }
}
@media(min-width: 1650px) {
    #center-bar {
        width: 73%;
        height: 90%;
    }   
}

#start-chat {
    background-color: #FFFFFF;
    border-radius: 14px;
    width:100%;
    height:100%;
}
.start-chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.start-chat-container img{
    width: 170px;
    height: 170px;
    border-radius: 50%;
}
.start-chat-container p{
    font-family: "Poppins",sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #646464;
    line-height: 28px;
    text-align: center;
    margin-top: 2rem;
}
.start-chat-container h2{
    font-family: "Poppins",sans-serif;
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 27px;
    line-height: 47px;
    color: #646464;
    margin-top: 2rem;
}
@media(max-width: 900px) {
  #start-chat {
      display: none;
  }
}
@media(min-width: 1650px) {
    .start-chat-container img{
        width: 200px;
        height: 200px;
    }
    .start-chat-container p{
        font-size: 18px;
    }
    .start-chat-container h2{
        font-size: 33px;
    }
}
#chat {
    background-color: #FFFFFF;
    border-radius: 14px;
    width:100%;
    height:100%;
    position: relative;
}
#chat .top-chat-content {
   display: flex;  
   width: 100%;
   border-bottom: .5px solid rgba(223, 223, 223, 0.8);
   padding:5px 15px; 
   justify-content: space-between;
   align-items: center;
   height:15%;
}
#chat .center-chat-content  {
   display: flex;  
   width: 100%;
   height:calc(100% - 30%);
   flex-direction: column-reverse;
   height: auto;
   display: flex;
   flex-direction: column-reverse;
   justify-content: flex-start;
   padding: 0 2.5rem;
   position: relative;
   width: 100%;
   height: calc(100% - 30%);
   overflow-y: auto;
   padding:1.1rem 2rem .9rem 2rem;
   padding: 15px 15px;
}
.back-to-friend span{
   color: #6B6B6B;
}
#chat .bottom-chat-content  {
   display: flex;  
   width: 100%;
   justify-content: space-between;
   align-items: center;
   height:15%;

}
#chat .top-chat-content img{
    width: 40px;
    height:40px;
    border-radius: 50%;
 }


#chat .profile-chat{
   display: flex;
   justify-content: center;
   align-items: center;
 }
#chat .left-top-chat-content{
   display: flex;
   justify-content: center;
   align-items: center;
 }

#chat .profile-chat h3{
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   font-size: 15px;
   margin-left: 10px;
   color:#6B6B6B;
}
.top-chat-content .MuiSvgIcon-root {
    fill: #717171 !important;
    cursor: pointer;
}
.file_input, .file_input_img {
   display: none;
}

#bottom-bar-chat {
   display: flex;
   height:100%;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   padding:0 15px;
}
.chat-input {
   display: flex;
   width: calc(100%);
   justify-content: space-between;
   align-items: center;
   background: #F2F2F2;
   padding: 0 15px;
   height:75%;
   max-height: 50px;
   border-radius: 14px;
}
.input-interactive {
   display: flex;
   align-items: center;
}
.chat-input input{
   padding: 0 8px;
   height:15px;
   width: 92%;
   border: none;
   outline: none;
   background: transparent;
}
.chat-input input::-webkit-input-placeholder {
   font-family: "Poppins", sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: #7D7D7D;
}
.chat-input input:-ms-input-placeholder {
   font-family: "Poppins", sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: #7D7D7D;
}
.chat-input input, .chat-input input::placeholder {
   font-family: "Poppins", sans-serif;
    font-size: .8rem;
    font-weight: 400;
    color: #7D7D7D;
}
.input-interactive .MuiSvgIcon-root{
   margin-left: 4px;
   margin-right: 4px;
   font-size: 1.2rem !important;
   cursor: pointer;
}
.send .MuiSvgIcon-root {
   font-size: 1.4rem !important;
   -webkit-transform: rotate(-25deg);
           transform: rotate(-25deg);
   cursor: pointer;
}
.send {
   margin-left: 15px;
}
.chat-messages-container {
  
}

::-webkit-scrollbar {
   width: 4px;
}
::-webkit-scrollbar-thumb {
   background: #cccbcb;
   border-radius: 8px;
}
::-webkit-scrollbar-track {
   background: #fff;
}

.center-chat-content-no-messages {
   width:100%;
   height: calc(100% - 30%);
   display: flex;
   justify-content: center;
   align-content: center;
}
.center-chat-content-no-messages .chat-messages-container{
   display: inline-flex;
   justify-content: center;
   align-content: center;
   flex-direction: column;
}
.center-chat-content-no-messages .chat-messages-container img { 
   width: 100px;
   height: 100px;
   border-radius: 50%;
   margin: 0 auto;
}

.center-chat-content-no-messages .chat-messages-container h3 { 
   font-family: "Poppins", sans-serif;
   font-weight: 500;
   font-size: 23px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #646464;
   margin-top: 10px;
}
.emoji-x {
   position: absolute;
   bottom:2%;
   right: 2%;
}
aside.emoji-picker-react {
   padding: 10px 0;
   border-radius: 12px !important;
}
.more-chat {
   display: inline-flex;
   background: #FCFCFC;
   box-shadow: 0px 4px 15px rgba(202, 202, 202, 0.25);
   border-radius: 12px;
   flex-direction: column;
   align-content: center;
   padding: 10px 10px;
   position: absolute;
   top:10%;
   right: 7%;
   z-index: 100;
}
.more-chat .content-more-chat{
   display: inline-flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;
   padding:15px 20px;
   border-radius: 14px;
}
.more-chat .content-more-chat:hover{
   background: #f0f0f0;
}
.more-chat .content-more-chat h4{
   margin-left: 10px;
   margin-right: 30px;
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   font-size: 14px;
   color: #717171;
}
.more-chat span{
   font-size: 1.5rem !important;
   color:  #717171 !important;
}
.back-from-settings {
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: "Poppins", sans-serif;
   cursor: pointer;
}
.back-from-settings h4{
   font-size: 16px;
   font-weight: 400;
   color: #6B6B6B;
   margin-left: 10px;
}
.back-from-settings span{
   /* font-size: 25px;
   font-weight: 400;
   color: #6B6B6B; */
   color: #6B6B6B;
}
.info-chat {
   width: 100%;
   padding:2rem 2.5rem .9rem 2.5rem;
   font-family: "Poppins", sans-serif;
   color: #717171;
}
.info-chat h4{
   font-size: 25px;
   color: #646464;
   font-weight: 400;
}
.info-chat em{
   color: #646464;
}
.info-chat .info-theme{
  display: flex;
  align-items: center;
}
.info-chat .info-theme div{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 15px;
}
.info-chat p{
 margin-top: 15px;
}

.back-to-friend {
   display: none;
}
@media(max-width:1150px) {
   .back-to-friend {
      display:flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 15px;
   } 
}

@media(min-height:700px) {
   #chat .top-chat-content {
      height:13%;
   }
   #chat .center-chat-content  {
      height:calc(100% - 26%);
   }
   #chat .bottom-chat-content  {
      height:13%;
   }
   .center-chat-content-no-messages {
      height:calc(100% - 26%);  
   }
}
@media(min-width:1150px) {
   #chat .top-chat-content {
      padding: 10px 25px;
  }
}

@media(min-width: 1650px) {
   #chat .top-chat-content {
      width: 100%;
      padding:1rem 2rem;
      height:12%;
   }
   #chat .center-chat-content {
     padding: 1rem 2rem;
   }
   #chat .top-chat-content img{
      width: 50px;
      height:50px;
      border-radius: 50%;
   }
   #chat .profile-chat h3{
      font-size: 19px;
      margin-left: 15px;
   }
   .more-chat {
      display: inline-flex;
      background: #FCFCFC;
      box-shadow: 0px 4px 15px rgba(202, 202, 202, 0.25);
      border-radius: 12px;
      flex-direction: column;
      align-content: center;
      padding: 10px 10px;
      position: absolute;
      top:5%;
      right: 5%;
      z-index: 100;
   }
   #bottom-bar-chat {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding:1rem 2rem;
   }
   .center-chat-content-no-messages .chat-messages-container img { 
      width: 120px;
      height: 120px;
   }
   
   .center-chat-content-no-messages .chat-messages-container h3 { 
      font-size: 25px;
      margin-top: 2rem;
   }
   .chat-input{
      max-height: 75px;
      height: 80%;
  }
   #chat .top-chat-content {
   height:12%;
   }
   #chat .center-chat-content  {
      height:calc(100% - 24%);
   }
   #chat .bottom-chat-content  {
      height:12%;
   }
   .center-chat-content-no-messages {
      height:calc(100% - 24%);  
   }
   .send {
      margin-left: 25px;
  }
}
#message  {
    display: flex;
    align-items: center;
}
#message  div{
    padding: 12px 19px;
    margin-top: 15px;
    border-radius: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 12.8px;
    max-width: 85%;
}
.my-message {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
}
.other-message {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}
.my-message div{
    color: #ECEBF3;
}
.other-message div{
    background-color: #F2F2F2;
}
@media(min-width: 900px) {
    #message  div{
        max-width: 55%;
    }
}
@media(min-width: 1650px) {
    #message  div{
        padding: 13px 20px;
        margin-top: 15px;
        font-size: 14px;
        max-width: 45%;
    } 
}
.line {
    text-decoration: underline;
    cursor: pointer;
}
#message-img {
    display: flex;
    align-items: center;
}
#message-img img{
    width: auto;
    max-width: 350px;
    max-height: 350px;
    height: auto;
    border-radius: 14px;
    margin-top: 15px;
    object-fit: cover;
}
.message-info-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
.message-info-content div{
   margin-left: 5px;
}
.info div{
    background: transparent;
    color: #6B6B6B;
}
#message-info {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    padding:20px 0;
}
#message-info p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 13px;
    color: #6B6B6B;
}
.settings-chat-container {
    padding:5px 1px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.settings-chat-container div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
    margin: 10px auto;
    background: #fdfdfd;
    transition: background .2s ease-in-out;
    cursor: pointer;
    border-radius: 12px;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 4px 4px rgba(243, 243, 243, 0.5)
}
.settings-chat-container div:hover{
    background: #f8f8f8;
}
.settings-chat-container .chat-theme-s span{
    width: 25px;
    height: 25px;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    border-radius: 50%;
}
.settings-chat-container div h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;
}
.settings-chat-container span {
    font-weight: 300;
    font-size: 22px;
    color: #6B6B6B;
    margin-bottom: 7px;
}

.changing {
    height: calc(100% - 12%);
    width:100%;
    position: relative; 
}
.changing-intro {
    width: 100%;
    justify-content: space-between;
    padding:2rem 35px .9rem 35px;
    display: flex;
}
.changing-intro h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B; 
}
.changing-intro span{
    font-weight: 400;
    font-size: 22px;
    color: #6B6B6B; 
    cursor: pointer;
}
.chat-content-change {
    height: calc(100% - 25%);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-theme-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3px;
}
.col-theme {
    padding: 12px;
    border-radius: 12px;
    transition: background .2s ease-in-out;
}
.col-theme:hover {
    background: #f0f0f0;
}
.col-theme div{
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-radius: 50%;
    cursor: pointer;
}
.col-theme.active {
    background: #f0f0f0;
}
@media(min-height:600px) {
    .col-theme div{
        width: 25px;
        height: 25px;
    } 
    .color-theme-box {
    grid-gap: 4px;
    }  
}
.chat-name-box input{
    padding:10px 12px;
    border-radius: 14px;
    border: .5px solid #6B6B6B;
    outline: none;
    width: 80%;
    margin-right: 20px;
}
.chat-name-box button{
    padding:10px 8px;
    border-radius: 14px;
    border: .5px solid transparent;
    outline: none;
    width: 20%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}
.chat-name-box {
    display: flex;
    width: 80%;
}
.chat-name-box input::-webkit-input-placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   font-weight: 400;
   color: #6B6B6B;
}
.chat-name-box input:-ms-input-placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   font-weight: 400;
   color: #6B6B6B;
}
.chat-name-box input, .chat-name-box input::placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   font-weight: 400;
   color: #6B6B6B;
}

.delete-opacity-all .delete-box{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 15px;
   color: #6B6B6B;
   font-weight: 400;
   width: 90%;
   margin: 0 auto;
   text-align: center;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 20px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box .btn{
   font-family: "Poppins", sans-serif;
   font-size: 12px;
   color: #6B6B6B;
   font-weight: 400;
   padding: 12px 30px;
   border: none;
   outline: none;
   border-radius: 14px;
   margin-left: 7px;
   margin-right:7px ;
   margin-top: 25px;
   cursor: pointer;
}
.delete-opacity-all .delete-box .delete-btn{
   background: #D03030;
   color: white;
}

/* @media (min-width:900px) {

    .settings-chat-container {
        padding:1.1rem 2rem .9rem 2rem;
    }
    
    .settings-chat-container div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 25px;
        margin-top: 5px;
        margin-bottom: 5px;
        background: #fdfdfd;
        transition: background .2s ease-in-out;
        cursor: pointer;
        border-radius: 12px;
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: 0px 4px 4px rgba(243, 243, 243, 0.5)
    }
    .settings-chat-container div h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;
}
.settings-chat-container span {
    font-weight: 300;
    font-size: 30px;
    color: #6B6B6B;
    margin-bottom: 15px;
}
.chat-content-change {
    height: calc(100% - 15%);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-theme-box {
    width: calc(5 * 45px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}
.col-theme {
    padding: 12px;
    border-radius: 12px;
    transition: background .2s ease-in-out;
}
.col-theme:hover {
    background: #f0f0f0;
}
.col-theme div{
    width: 40px;
    height: 40px;
    border: 3px solid transparent;
    border-radius: 50%;
    cursor: pointer;
}
.col-theme.active {
    background: #f0f0f0;
}

.chat-name-box input{
    padding:12px 15px;
    border-radius: 14px;
    border: .5px solid #6B6B6B;
    outline: none;
    width: 80%;
    margin-right: 20px;
}
.chat-name-box button{
    padding:12px 10px;
    border-radius: 14px;
    border: .5px solid transparent;
    outline: none;
    width: 20%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}
.chat-name-box {
    display: flex;
    width: 45%;
}
.chat-name-box input, .chat-name-box input::placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 15px;
   font-weight: 400;
   color: #6B6B6B;
}

.delete-opacity-all .delete-box{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 17px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 20px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box .btn{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   color: #6B6B6B;
   font-weight: 400;
   padding: 14px 35px;
   border: none;
   outline: none;
   border-radius: 14px;
   margin-left: 7px;
   margin-right:7px ;
   margin-top: 20px;
   cursor: pointer;
}
.delete-opacity-all .delete-box .delete-btn{
   background: #D03030;
   color: white;
}
} */

@media (min-width: 1150px) {
  .settings-chat-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding:1.3rem 2rem;
      flex-direction: row;
      width: auto;
  }
  .settings-chat-container div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        width: 150px;
        margin-left: 20px;
    }
    .changing-intro span {
        font-size: 20px;
    }
    .changing-intro h4 {
        font-size: 16px;
    }
    .chat-name-box input::-webkit-input-placeholder {
        font-size: 15px;
    }
    .chat-name-box input:-ms-input-placeholder {
        font-size: 15px;
    }
    .chat-name-box input, .chat-name-box input::placeholder {
        font-size: 15px;
    }
    .chat-name-box input {
        padding: 12px 17px;
        margin-right: 15px;
    }
    .chat-name-box button {
        padding: 10px 8px;
        border-radius: 14px;
        border: 0.5px solid transparent;
        outline: none;
        width: 20%;
        color: white;
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
    }
    .col-theme div {
        width: 30px;
        height: 30px;
    }
    .chat-content-change {
        height: calc(100% - 20%);
    }
}
#profile-settings {
    height: 100vh;
    background-color: #eceafc;
}
#profile-settings .dashboard-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    height: 100%;
}
#profile-settings#center-bar {
   width: 50% !important;
}


#profile-settings #center-bar .setting-title {
   font-family: "Poppins", sans-serif;
   color: #6a6a6a;
   font-size: 20px;
   font-weight: 400;
}
#profile-settings #center-bar  {
   background: white;
   position: relative;
   top: 0;
   left: 0;
   -webkit-transform: translate(0);
           transform: translate(0);
   width: 95%;
   max-width: 700px;
   height: 95%;
   margin: 0 auto;
   border-radius: 14px;
}
#profile-settings #center-bar {
   display: flex;
   justify-content: center;
   align-items: center;
}
#profile-settings #center-bar .wrapper-center-bar {
   height: 92%;
   width: 90%;
}
#profile-settings #center-bar .choose-setting {
  display: grid;
  grid-template-columns: 1fr;
  max-height: 200px;
  height: 100%;
  grid-gap:20px;
  gap:20px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-top:4rem;
}
#profile-settings #center-bar .choose-setting div{
   background-color: rgba(241, 241, 241, 0.267);
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   transition: background .2s ease-in;
   cursor: pointer;
   border-radius: 12px;
}
#profile-settings #center-bar .choose-setting div:hover{
    background-color: rgb(240, 240, 240);
}

#profile-settings #center-bar .choose-setting div{
   color: #6a6a6a;
   font-family: "Poppins", sans-serif;
}
#profile-settings #center-bar .choose-setting h4{
   font-weight: 500;
   margin-top: 20px;
}
.wrp-title {
    display: flex;
    align-items: center;
    color: #6a6a6a;
}
.wrp-title span{
    margin-right: 10px;
    font-size: 30px;
    cursor: pointer;
}
#center-bar .center-setting{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
#center-bar .center-setting.password{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.add-picture {
   width: 200px;
   height: 200px;
   border-radius: 50%;
   position: relative;
   cursor: pointer;
}

.box-set {
   background: #fcfcfc;
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   height: 70px;
   padding: 25px 25px;
   font-family: "Poppins", sans-serif;
   cursor: pointer;
   transition: background .2s ease;
   box-shadow: 1px 2px 10px 1px rgba(240, 240, 240, 0.527);
}
.box-set:hover {
   background: #f5f5f5;
}
.box-set div{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.image-change{
   width: 200px;
   border-radius: 50%;
   background: rgb(190, 190, 190);
   height: 200px;
   position: relative;
}
.image-change img{
   width: 100%;
   height: 100%;
   border-radius: 50%;
}
.image-change img:hover{
   opacity: 0.5;
}
.image-change img:hover + .plus-pr{
    display: block;
}
.box-set h4{
   font-weight: 300;
   color: #6b6b6b;
   font-size: 15px;
}
.box-set h2{
   font-weight: 500;
   color: #444444;
   font-size: 19px;
}
.box-set h3{
   font-weight: 500;
   color: #272727;
   font-size: 18px;
}
.choose-set {
   display: grid;
   grid-template-columns: 1fr;
   margin-top: 2rem;
   grid-gap:20px;
   gap:20px;
}
.btn-set {
   background: #635ac0bd;
   border: none;
   outline: none;
   padding: 14px 25px;
   color: white;
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   border-radius: 14px;
   cursor: pointer;
   border: 2px solid #635ac0bd;
   transition: background .2s ease;
}
.btn-change {
   background: #635ac0;
   padding: 20px 28px;
   color: white;
   border: none;
   outline: none;
   border-radius: 12px;
   font-weight: 600;
   font-size: 12px;
   font-family: "Poppins", sans-serif;
   cursor: pointer;
   margin-top: 3rem;
   transition: background .2s ease;
}
.btn-change:hover {
   background: #635ac0dc;
}
.plus-pr {
   font-size: 3rem;
   font-weight: 800;
   z-index: -1;
   cursor: default;
   color: #635ac0;
   position: absolute;
   top:50%;
   left:50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
   display: none;
}

#profile-settings #center-bar .MuiSvgIcon-root {
   fill: #625AC0 !important;
   font-size: 1.5rem !important;
}
.flex-inputs-set {
   display: flex;
   
   width: 400px;
   flex-direction: column;
}
.flex-inputs-set .input{
   width: 100%;
   padding:12px 14px;
   border-radius: 10px;
   margin-top: 1.5rem;
}
.dashboard-component-wrapper  .visibility-password {
   cursor: pointer;
   width: 1.3rem;
}


#profile-settings .flex-inputs-set {
   display: flex;
   width: 100%;
   flex-direction: column;
}

#profile-settings .profile .MuiSvgIcon-root {
   fill: #494949 !important;
   transition: -webkit-transform .2s ease-in-out;
   transition: transform .2s ease-in-out;
   transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

#profile-settings .profile-options .MuiSvgIcon-root {
   color: #5f5f5f !important;
}

.wrp-title span {
   font-size: 25px;
   font-weight: lighter;
   margin-left: -5px;
}

@media(min-width:1150px) {
   #profile-settings #center-bar  {
      width: 55% !important;
      height: 85%;
   }
   .choose-set {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
              column-gap: 20px;
  }
     .box-set {
      height: 120px;
      margin: 0 auto;
      width: 100%;
   }
   .box-set h4{
      font-size: 13px;
   }
   #profile-settings #center-bar .wrapper-center-bar {
      height: 90%;
      width: 87%;
  }
  #profile-settings .flex-inputs-set {
   width: 400px;
}
}

@media (min-width: 1650px) {
   #profile-settings #center-bar .wrapper-center-bar {
       width: 92%;
   }
   .box-set {
      height: 140px;
  }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rotate-animation {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.scale-animation {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
