@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

body {
  overflow: hidden;
}
#sign-up {
    background-color: #E6E4F9;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}



@media (min-width: 1100px) and (max-height: 590px) {
  #sign-up {
    height: 45rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
@media (min-width: 1650px) and (max-height: 830px) {
  #sign-up {
    height: 60rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
