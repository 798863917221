#profile-settings #center-bar .setting-title {
   font-family: "Poppins", sans-serif;
   color: #6a6a6a;
   font-size: 20px;
   font-weight: 400;
}
#profile-settings #center-bar  {
   background: white;
   position: relative;
   top: 0;
   left: 0;
   transform: translate(0);
   width: 95%;
   max-width: 700px;
   height: 95%;
   margin: 0 auto;
   border-radius: 14px;
}
#profile-settings #center-bar {
   display: flex;
   justify-content: center;
   align-items: center;
}
#profile-settings #center-bar .wrapper-center-bar {
   height: 92%;
   width: 90%;
}
#profile-settings #center-bar .choose-setting {
  display: grid;
  grid-template-columns: 1fr;
  max-height: 200px;
  height: 100%;
  gap:20px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-top:4rem;
}
#profile-settings #center-bar .choose-setting div{
   background-color: rgba(241, 241, 241, 0.267);
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   transition: background .2s ease-in;
   cursor: pointer;
   border-radius: 12px;
}
#profile-settings #center-bar .choose-setting div:hover{
    background-color: rgb(240, 240, 240);
}

#profile-settings #center-bar .choose-setting div{
   color: #6a6a6a;
   font-family: "Poppins", sans-serif;
}
#profile-settings #center-bar .choose-setting h4{
   font-weight: 500;
   margin-top: 20px;
}
.wrp-title {
    display: flex;
    align-items: center;
    color: #6a6a6a;
}
.wrp-title span{
    margin-right: 10px;
    font-size: 30px;
    cursor: pointer;
}
#center-bar .center-setting{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
#center-bar .center-setting.password{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.add-picture {
   width: 200px;
   height: 200px;
   border-radius: 50%;
   position: relative;
   cursor: pointer;
}

.box-set {
   background: #fcfcfc;
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   height: 70px;
   padding: 25px 25px;
   font-family: "Poppins", sans-serif;
   cursor: pointer;
   transition: background .2s ease;
   box-shadow: 1px 2px 10px 1px rgba(240, 240, 240, 0.527);
}
.box-set:hover {
   background: #f5f5f5;
}
.box-set div{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.image-change{
   width: 200px;
   border-radius: 50%;
   background: rgb(190, 190, 190);
   height: 200px;
   position: relative;
}
.image-change img{
   width: 100%;
   height: 100%;
   border-radius: 50%;
}
.image-change img:hover{
   opacity: 0.5;
}
.image-change img:hover + .plus-pr{
    display: block;
}
.box-set h4{
   font-weight: 300;
   color: #6b6b6b;
   font-size: 15px;
}
.box-set h2{
   font-weight: 500;
   color: #444444;
   font-size: 19px;
}
.box-set h3{
   font-weight: 500;
   color: #272727;
   font-size: 18px;
}
.choose-set {
   display: grid;
   grid-template-columns: 1fr;
   margin-top: 2rem;
   gap:20px;
}
.btn-set {
   background: #635ac0bd;
   border: none;
   outline: none;
   padding: 14px 25px;
   color: white;
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   border-radius: 14px;
   cursor: pointer;
   border: 2px solid #635ac0bd;
   transition: background .2s ease;
}
.btn-change {
   background: #635ac0;
   padding: 20px 28px;
   color: white;
   border: none;
   outline: none;
   border-radius: 12px;
   font-weight: 600;
   font-size: 12px;
   font-family: "Poppins", sans-serif;
   cursor: pointer;
   margin-top: 3rem;
   transition: background .2s ease;
}
.btn-change:hover {
   background: #635ac0dc;
}
.plus-pr {
   font-size: 3rem;
   font-weight: 800;
   z-index: -1;
   cursor: default;
   color: #635ac0;
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
   display: none;
}

#profile-settings #center-bar .MuiSvgIcon-root {
   fill: #625AC0 !important;
   font-size: 1.5rem !important;
}
.flex-inputs-set {
   display: flex;
   
   width: 400px;
   flex-direction: column;
}
.flex-inputs-set .input{
   width: 100%;
   padding:12px 14px;
   border-radius: 10px;
   margin-top: 1.5rem;
}
.dashboard-component-wrapper  .visibility-password {
   cursor: pointer;
   width: 1.3rem;
}


#profile-settings .flex-inputs-set {
   display: flex;
   width: 100%;
   flex-direction: column;
}

#profile-settings .profile .MuiSvgIcon-root {
   fill: #494949 !important;
   transition: transform .2s ease-in-out;
}

#profile-settings .profile-options .MuiSvgIcon-root {
   color: #5f5f5f !important;
}

.wrp-title span {
   font-size: 25px;
   font-weight: lighter;
   margin-left: -5px;
}

@media(min-width:1150px) {
   #profile-settings #center-bar  {
      width: 55% !important;
      height: 85%;
   }
   .choose-set {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
  }
     .box-set {
      height: 120px;
      margin: 0 auto;
      width: 100%;
   }
   .box-set h4{
      font-size: 13px;
   }
   #profile-settings #center-bar .wrapper-center-bar {
      height: 90%;
      width: 87%;
  }
  #profile-settings .flex-inputs-set {
   width: 400px;
}
}

@media (min-width: 1650px) {
   #profile-settings #center-bar .wrapper-center-bar {
       width: 92%;
   }
   .box-set {
      height: 140px;
  }
}