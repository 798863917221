.wave {
    position: absolute;
    top:0;
    right: 0;
    height: 100vh;
    display: none;
}

@media (min-width: 1100px){
  .wave {
      display: block;
  }
}