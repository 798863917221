#rights {
    position: absolute;
    bottom: 2rem;
    right: 3rem;
    font-family: 'Poppins', sans-serif;
    display: none;
}
@media(min-width: 1100px) {
    #rights {
        display: block;
    }
} 