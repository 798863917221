#left-bar-main {
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 550px;
    height: 95%;
    z-index: 10;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 14px;
    position: relative;
}
.top-left-search {
    margin: 0 auto;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choose-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.choose-left h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    color: #494949;
}
.choose-left .active{
    color: #625AC0;
}

.createGroupBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #625AC0;
    position: absolute;
    right: 7%;
    bottom: 4%;
    cursor: pointer;
}
.createGroupBtn::after {
    content: "";
    width: 45%;
    height: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.createGroupBtn::before {
    content: "";
    width: 3px;
    height: 45%;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.back-from-create {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #625AC0;
    cursor: pointer;
}
.back-from-create h4{
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
}
.back-from-create span{
    font-weight: 300;
}
.group-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
}
.group-name-wrapper input {
    background-color: #ECEAFC;
    outline: none;
    border: none;
    border-radius: 12px;
    padding: 12px 15px;
    width: 70%;
}
.group-name-wrapper button {
    width: 30%;
    margin-left: 8px;
    background-color: #625AC0;
    outline: none;
    border: none;
    border-radius: 12px;
    padding: 12px 0;
    cursor: pointer;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.3px;
}
.group-name-wrapper input, .group-name-wrapper input::placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}


@media (min-width:1150px) {
    #left-bar-main {
        width: 27%;
        padding: 0;
        max-width: auto;
        top:0;
        left:0;
        transform:translate(0);
        height: 90%;
        border-radius: 14px;
        position: relative;
        margin-right: 20px;
    }    
    .top-left-search {
        margin: 0 auto;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media(min-width: 1650px) {
    #left-bar-main {
        width: 27%;
        /* padding: 1.2rem 1.1rem 1.2rem 1.1rem; */
        height: 90%;
        border-radius: 14px;
        position: relative;
    }
    .top-left-search {
        margin: 0 auto;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}