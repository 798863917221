
#forgot-password {
    background-color: #E6E4F9;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}



@media (min-width: 1100px) and (max-height: 590px) {
  #forgot-password{
    height: 65rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
@media (min-width: 1650px) and (max-height: 830px) {
  #forgot-password {
    height: 60rem;
    position: relative
  }
  body {
    overflow: auto;
  }
}
  