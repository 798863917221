#top-bar {
    width: 100%;
    box-shadow: 0px 4px 6px rgba(235, 235, 235, 0.25);
    height: 13vh;
    z-index: 1000;
    background-color: white;
}

#top-bar .top-bar-container {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
}

@media(min-width: 1650px) {
 #top-bar {
     height: 13vh;
 }  
}