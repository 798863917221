@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');

#form {
    position: absolute;
    top:0%;
    left:0%;
    transform: translate(0%, 0%);
    height: 100vh;
    width: 100%;
}

#form .form-wrapper{
    display: inline-block;
    border-radius: 0;
    z-index: 999;
    position: relative;
    width: 100%;
    background-color: #625AC0;
    height: 100%;
}
#form .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.form-rights {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size:0.75rem;
    font-weight: 300;
    font-family: 'Karla', sans-serif;
    color: white;
}
.inputs {
    display: flex;
    flex-direction: column;
    padding: .8rem 1.6rem 1rem 1.6rem;
    width: 100%;
}
.flex-inputs {
    display: flex;
}
.flex-inputs .input:nth-child(2){
    margin-left: 14px;
}
#form .MuiSvgIcon-root {
    fill: #625AC0 !important;
    font-size: 1.5rem !important;
}
#form  .visibility-password {
    font-size: 1rem !important;
    cursor: pointer;
}
@media (min-width: 450px) {
    .flex-inputs .input{
       width: calc(320px/2 - 7px);
    } 
    .inputs {
        width: auto;
    }
}
@media (min-width: 500px) {
    .flex-inputs .input{
       width: calc(380px/2 - 7px);
    } 
}
@media(min-width:1100px) {
    .form-rights {
       display: none;
    }
    #form {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;
    }
    #form .form-wrapper{
        padding:3rem 0 1.5rem 0;
        width: 100%;
        border-radius: 2rem;
    }
    .inputs {
        padding: .8rem 2.1rem 1rem 2.1rem;
    }
    .flex-inputs .input{
        width: calc(350px/2 - 12px);
     }
     .flex-inputs .input:nth-child(2) {
        margin-left: 24px;
    }
    .MuiSvgIcon-root {
        font-size: 1.5rem !important;
    }
    .visibility-password {
        font-size: 1rem !important;
    }
}
@media(min-width:1650px) {
    #form .form-wrapper{
        padding:4rem 0 1.9rem 0;
    }
    .inputs {
        padding: .8rem 2.6rem 1rem 2.6rem;
    }
    .flex-inputs .input{
        width: calc(470px/2 - 12px);
     }
     .flex-inputs .input:nth-child(2) {
        margin-left: 24px;
    }
    #form  .MuiSvgIcon-root {
        font-size: 2rem !important;
    }
    #form  .visibility-password {
        font-size: 1.2rem !important;
        cursor: pointer;
    }
}

@media(max-width: 380px) and (max-height: 480px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 45rem !important;
    }
}
@media(max-width: 380px) and (max-height: 560px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 42rem !important;
    }
}
@media(min-width: 381px) and (max-height: 484px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
}
@media(min-width: 381px) and (max-height: 560px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 36rem !important;
    }
    #sign-up #form {
        height: 42rem !important;
    }
}
@media(min-width: 420px) and (max-height: 420px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 37rem !important;
    }
}
@media(min-width: 500px) and (max-height: 600px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 40rem !important;
    }
}
@media(min-width: 700px) and (max-height: 650px) {
    body {
        overflow: auto !important;
    }
    #form {
        height: 42rem !important;
    }
}
@media(min-width: 1100px) {
    #form {
        height: auto !important;
    }
}